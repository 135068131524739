<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        TÀI LIỆU ĐÍNH KÈM
      </b-badge>
    </b-card-header>
    <b-card-body>
      <uploads
        id="uploads"
        ref="uploads"
        :key="'key-refresh-' + canRemoveFile"
        :files="files"
        :options="{autoProcessQueue:false, addRemoveLinks: canRemoveFile}"
        @file-uploaded="handleUploaded"
        @file-removed="handleRemoved"
        @all-done="handleAllDone"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Uploads from '@core/components/uploads/Uploads.vue'
import { ref } from '@vue/composition-api'
import ability from '@/libs/acl/ability'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    Uploads,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    documentFiles: {
      type: Array,
      default: () => [],
      required: true,
    },
    contractStatus: {
      type: String,
      default: 'entry_new',
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ability,
    }
  },
  computed: {
    canRemoveFile() {
      return ability().can('remove-file', 'Contract') || this.contractStatus === 'entry_new'
    },
  },
  watch: {
    documentFiles(n) {
      this.files = n
    },
  },
  methods: {
    handleUploaded(files = []) {
      this.files.push(...files)
      this.$emit('update:documentFiles', this.files)
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    handleAllDone() {
      this.$emit('all-updated', this.files)
    },
  },
  setup() {
    const files = ref([])

    return {
      files,
    }
  },
}
</script>
