<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        THÔNG TIN KHÁCH HÀNG
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-form @submit.prevent>
        <b-row>
          <!-- họ tên -->
          <b-col
            cols="12"
            sm="8"
          >
            <b-form-group
              label="Tên khách hàng"
              label-for="customerName"
            >
              <b-form-input
                id="customerName"
                v-model="contract.customer.name"
                placeholder="Tên khách hàng"
                class="text-primary"
              />
            </b-form-group>
          </b-col>

          <!--Số điện thoại-->
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group
              label="Số điện thoại"
              label-for="customerPhone"
            >
              <b-form-input
                id="customerPhone"
                v-model="contract.customer.phone"
                placeholder="Số điện thoại"
              />
            </b-form-group>
          </b-col>

          <!--Nhóm khách hàng-->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Nhóm khách hàng"
              label-for="customerCategory"
            >
              <v-select
                id="customerCategory"
                v-model="contract.customer.category"
                class="w-100"
                :reduce="category => category.value"
                :options="$customerCategory.list"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- mã số thuế -->
          <b-col
            v-if="contract.customer.category === 'company'"
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Mã số thuế"
              label-for="customerTaxCode"
            >
              <b-form-input
                id="customerTaxCode"
                v-model="contract.customer.tax_code"
                placeholder="MST"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="contract.customer.category === 'company'"
            cols="12"
            sm="6"
          />

          <!-- Địa chỉ -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Địa chỉ"
              label-for="customerAddress"
            >
              <b-form-input
                id="customerAddress"
                v-model="contract.customer.address"
                placeholder="Địa chỉ"
              />
            </b-form-group>
          </b-col>

          <!--Danh xưng-->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              label="Danh xưng"
              label-for="customerGender"
            >
              <b-form-input
                id="customerGender"
                v-model="contract.customer.rep_gender"
                placeholder="Danh xưng"
              />
            </b-form-group>
          </b-col>

          <!--Người đại diện pháp luật-->
          <b-col
            cols="12"
            sm="6"
            class="pl-sm-0"
          >
            <b-form-group
              label="Người đại diện pháp luật"
              label-for="customerRepName"
            >
              <b-form-input
                id="customerRepName"
                v-model="contract.customer.rep_name"
                placeholder="Người đại diện pháp luật"
                class="text-primary"
              />
            </b-form-group>
          </b-col>

          <!--Chức vụ-->
          <b-col
            cols="12"
            sm="3"
            class="pl-sm-0"
          >
            <b-form-group
              label="Chức vụ"
              label-for="customerRepJobTitle"
            >
              <b-form-input
                id="customerRepJobTitle"
                v-model="contract.customer.rep_job_title"
                placeholder="Chức vụ"
              />
            </b-form-group>
          </b-col>

          <!-- CCCD -->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              label="CCCD"
              label-for="cccd"
            >
              <b-form-input
                id="cccd"
                v-model="contract.customer.rep_card_id"
                placeholder="CCCD"
              />
            </b-form-group>
          </b-col>

          <!--ngày cấp-->
          <b-col
            cols="12"
            sm="6"
            lg="4"
            class="pl-sm-0"
          >
            <b-form-group
              label="Ngày cấp"
              label-for="ngaycap"
            >
              <flat-pickr
                id="ngaycap-cmnd-customer"
                v-model="contract.customer.rep_card_date"
                class="form-control"
                :config="{ altFormat: 'd/m/Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'd/m/Y', allowInput: false }"
              />
            </b-form-group>
          </b-col>

          <!--nơi cấp-->
          <b-col
            cols="12"
            sm="3"
            lg="5"
            class="pl-sm-0"
          >
            <b-form-group
              label="Nơi cấp"
              label-for="noicapaddress"
            >
              <b-form-input
                id="noicapaddress"
                v-model="contract.customer.rep_card_place"
                placeholder="CCCD"
              />
            </b-form-group>
          </b-col>

          <!--Người liên hệ-->
          <b-col
            cols="12"
            sm="5"
          >
            <b-form-group
              label="Người liên hệ"
              label-for="customerContactName"
            >
              <b-form-input
                id="customerContactName"
                v-model="contract.customer.contact_name"
                placeholder="Người liên hệ"
                class="text-primary"
              />
            </b-form-group>
          </b-col>

          <!--Email-->
          <b-col
            cols="12"
            sm="4"
            class="pl-sm-0"
          >
            <b-form-group
              label="Email"
              label-for="customerContactEmail"
            >
              <b-form-input
                id="customerContactEmail"
                v-model="contract.customer.contact_email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>

          <!--Số điện thoại-->
          <b-col
            cols="12"
            sm="3"
            class="pl-sm-0"
          >
            <b-form-group
              label="Số điện thoại"
              label-for="customerContactPhone"
            >
              <b-form-input
                id="customerContactPhone"
                v-model="contract.customer.contact_phone"
                placeholder="Số điện thoại"
              />
            </b-form-group>
          </b-col>

          <!-- Thông tin thêm -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Thông tin thêm "
              label-for="customerMore"
            >
              <b-form-input
                id="customerMore"
                v-model="contract.customer.more"
                placeholder="Thông tin thêm "
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BBadge,
    BFormInput,
    BForm,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
