<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        NỘI DUNG MUA BÁN NỢ
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-form @submit.prevent>
        <b-row>
          <!-- số tiền nợ gốc -->
          <b-col
            cols="12"
            sm="6"
            lg="4"
            class="pt-50 bg-primary bg-lighten-5"
          >
            <b-form-group
              label="SỐ TIỀN NỢ GỐC"
              label-for="amount_to_buy_original"
            >
              <b-input-group class="input-group-merge">
                <cleave
                  id="amount_to_buy_original"
                  v-model="contract.original_amount"
                  class="form-control text-primary"
                  :raw="true"
                  :options="formatOptions.number"
                  placeholder="Số tiền nợ gốc"
                />
                <b-input-group-append is-text>
                  đ
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Mức giá mua nợ gốc"
              label-for="fee_to_buy_original"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="fee_to_buy_original"
                  v-model="contract.fee_to_buy_original"
                  class="text-primary"
                  :formatter="v => $formatPercentMinMax(v)"
                  type="number"
                />
                <b-input-group-append is-text>
                  %
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Mức giá Song Long nhận"
            >
              <div class="text-primary font-weight-bolder">
                {{ parseFloat((100-contract.fee_to_buy_original).toFixed(3)) }} %
              </div>
            </b-form-group>

            <b-form-group
              label="Giá trị mua nợ gốc"
            >
              <div class="text-primary font-weight-bolder">
                {{ formatNumber(contract.amount_to_buy_original) }} đ
              </div>
            </b-form-group>

            <b-form-group
              label="Giá trị Song Long nhận"
            >
              <div class="text-primary font-weight-bolder">
                {{ formatNumber(contract.original_amount - contract.amount_to_buy_original) }} đ
              </div>
            </b-form-group>
          </b-col>

          <!--số tiền nợ lãi-->
          <b-col
            cols="12"
            sm="6"
            lg="4"
            class="pt-50 bg-primary bg-lighten-5"
          >
            <b-form-group
              label="SỐ TIỀN NỢ LÃI"
              label-for="interest_amount"
            >
              <b-input-group class="input-group-merge">
                <cleave
                  id="interest_amount"
                  v-model="contract.interest_amount"
                  class="form-control text-primary"
                  :options="formatOptions.number"
                  placeholder="Số tiền nợ lãi"
                />
                <b-input-group-append is-text>
                  đ
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Mức giá mua nợ lãi"
              label-for="fee_to_buy_interest"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="fee_to_buy_interest"
                  v-model="contract.fee_to_buy_interest"
                  :formatter="v => $formatPercentMinMax(v)"
                  class="text-primary"
                  type="number"
                />
                <b-input-group-append is-text>
                  %
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Mức giá Song Long nhận"
            >
              <div class="text-primary font-weight-bolder">
                {{ parseFloat((100-contract.fee_to_buy_interest).toFixed(3)) }} %
              </div>
            </b-form-group>

            <b-form-group
              label="Giá trị mua nợ lãi"
            >
              <div class="text-primary font-weight-bolder">
                {{ formatNumber(contract.amount_to_buy_interest) }} đ
              </div>
            </b-form-group>

            <b-form-group
              label="Giá trị Song Long nhận"
            >
              <div class="text-primary font-weight-bolder">
                {{ formatNumber(contract.interest_amount - contract.amount_to_buy_interest) }} đ
              </div>
            </b-form-group>
          </b-col>

          <!--phí tư vấn-->
          <b-col
            cols="12"
            sm="6"
            lg="4"
            class="pt-50"
          >
            <b-form-group
              label="Đợt 1: Sau khi nhận hồ sơ"
              label-for="feeForConsultant_Input"
            >
              <b-input-group class="input-group-merge">
                <cleave
                  id="feeForConsultant_Input"
                  v-model="contract.fee_for_consultant_input"
                  placeholder="Đợt 1: Sau khi nhận hồ sơ"
                  class="form-control text-primary"
                  :options="formatOptions.number"
                />
                <b-input-group-append is-text>
                  đ
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Đợt 2: Sau khi ký hợp đồng"
              label-for="feeForConsultant_Contract"
            >
              <b-input-group class="input-group-merge">
                <cleave
                  id="feeForConsultant_Contract"
                  v-model="contract.fee_for_consultant_contract"
                  placeholder="Đợt 2: Sau khi ký hợp đồng"
                  class="form-control text-primary"
                  :options="formatOptions.number"
                />
                <b-input-group-append is-text>
                  đ
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <label
                class="w-100 d-flex align-items-center"
                label="Đợt 3: Sau khi thu nợ đạt % giá trị nợ"
                label-for="feeForConsultant_Service"
              >
                Đợt 3: Sau khi thu nợ đạt &nbsp;
                <b-form-input
                  id="fee_for_consultant_service_percent"
                  v-model="contract.ratio_of_pay_consultant"
                  type="number"
                  class="inline-input text-primary border-primary"
                  placeholder="20"
                />&nbsp;<strong class="text-primary">%</strong> &nbsp;
                giá trị nợ
              </label>
              <b-input-group class="input-group-merge">
                <cleave
                  id="feeForConsultant_Service"
                  v-model="contract.fee_for_consultant_service"
                  placeholder="Đợt 3: Sau khi thu nợ đạt % giá trị nợ"
                  class="form-control text-primary"
                  :options="formatOptions.number"
                />
                <b-input-group-append is-text>
                  đ
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Tổng phí tư vấn"
              class="mt-2"
            >
              <div
                class="text-primary font-weight-bolder"
              >
                {{ formatNumber(contract.fee_for_consultant) }} đ
              </div>
            </b-form-group>
            <b-form-group
              label="Ghi chú"
              label-for="debtDescription"
            >
              <b-form-input
                id="debtDescription"
                v-model="contract.debt_description"
                placeholder="Ghi chú"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BBadge,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BBadge,
    BInputGroup,
    BForm,
    Cleave,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
}
</script>
