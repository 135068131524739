<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50 text-wrap text-left"
      >
        THÔNG TIN KHÁCH NỢ DO KHÁCH HÀNG CUNG CẤP
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-form @submit.prevent>
        <b-row>
          <!--Tình trạng hiện tại-->
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <b-form-group
              label="Tình trạng khách nợ hiện tại"
              label-for="infoStatus"
            >
              <b-form-input
                id="infoStatus"
                v-model="contract.info_status"
                placeholder="Tình trạng khách nợ"
              />
            </b-form-group>
            <b-form-group
              label="Biết công ty CP ĐT mua bán nợ Song Long qua đâu?"
              label-for="replyDate"
            >
              <v-select
                id="info_how_to_know_me"
                v-model="contract.info_how_to_know_me_type"
                label="text"
                :options="$howKnowMe"
                :clearable="false"
              />
              <br>
              <b-form-input
                v-if="contract.info_how_to_know_me_type && contract.info_how_to_know_me_type.value === 'khac'"
                id="info_how_to_know_me-other"
                v-model="contract.info_how_to_know_me"
                placeholder="Biết Song Long qua đâu?"
              />
            </b-form-group>
          </b-col>

          <!--Ngành nghề hoạt động-->
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <b-form-group
              label="Ngành nghề hoạt động"
              label-for="infoWorkingArea"
            >
              <b-form-input
                id="infoWorkingArea"
                v-model="contract.info_working_area"
                placeholder="Ngành nghề hoạt động"
              />
            </b-form-group>
            <b-form-group
              label="Mối quan hệ giữa KH-KN"
              label-for="infoDebtRelationship"
            >
              <b-form-input
                id="infoDebtRelationship"
                v-model="contract.info_debt_relationship"
                placeholder="Mối quan hệ giữa KH-KN"
              />
            </b-form-group>
          </b-col>

          <!--thời gian vay-->
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <b-form-group
              label="Thời gian phát sinh"
              label-for="debtDuration"
            >
              <b-form-input
                id="debtDuration"
                v-model="contract.debt_duration"
                placeholder="Thời gian phát sinh"
              />
            </b-form-group>
            <b-form-group
              label="Lý do phát sinh nợ"
              label-for="debtReason"
            >
              <b-form-input
                id="debtReason"
                v-model="contract.debt_reason"
                placeholder="Lý do phát sinh nợ"
              />
            </b-form-group>
          </b-col>

          <!--Lý do không trả-->
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <b-form-group
              label="Lý do không trả"
              label-for="debtDenyReason"
            >
              <b-form-input
                id="debtDenyReason"
                v-model="contract.debt_deny_reason"
                placeholder="Lý do không trả"
              />
            </b-form-group>
          </b-col>

          <!--Ngày hẹn trả lời kết quả-->
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <b-form-group
              label="Ngày hẹn trả lời kết quả"
              label-for="reply_at"
            >
              <flat-pickr
                id="reply_at"
                v-model="contract.reply_at"
                class="form-control"
                :config="{ altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: false }"
              />
            </b-form-group>
            <b-form-group
              label="Khách hàng đã nhờ đơn vị/cơ quan"
              label-for="infoHasContactedWithGov"
            >
              <b-form-input
                id="infoHasContactedWithGov"
                v-model="contract.info_has_contacted_with_gov"
                placeholder="Khách hàng đã nhờ đơn vị/cơ quan"
              />
            </b-form-group>
          </b-col>

          <!--        Tài liệu Khách Hàng cung cấp-->
          <b-col
            cols="12"
            sm="6"
            lg="9"
          >
            <b-form-group
              label="Thông tin khác"
              label-for="infoDocumentAttachment"
            >
              <b-form-textarea
                id="infoDocumentAttachment"
                v-model="contract.info_more"
                placeholder="Tài liệu Khách Hàng cung cấp"
                rows="4"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!--        thông tin khác-->
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Tài liệu Khách Hàng cung cấp"
              label-for="infoMore"
            >
              <b-form-textarea
                id="infoMore"
                v-model="contract.info_document_attachment"
                placeholder="Tài liệu Khách Hàng cung cấp"
                rows="5"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  VBToggle,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    flatPickr,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
