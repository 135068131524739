<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header>
      <b-badge
        variant="light-primary"
        class="font-small-4 px-1 py-50"
      >
        THÔNG TIN KHÁCH NỢ
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-form @submit.prevent>
        <b-row>
          <!-- họ tên -->
          <b-col
            cols="12"
            sm="8"
          >
            <b-form-group
              label="Tên khách nợ"
              label-for="debtorName"
            >
              <b-form-input
                id="debtorName"
                v-model="contract.debtor.name"
                placeholder="Tên khách nợ"
                class="text-primary"
              />
            </b-form-group>
          </b-col>

          <!--Số điện thoại-->
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group
              label="Số điện thoại"
              label-for="debtorPhone"
            >
              <b-form-input
                id="debtorPhone"
                v-model="contract.debtor.phone"
                placeholder="Số điện thoại"
              />
            </b-form-group>
          </b-col>

          <!--Nhóm khách nợ-->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Nhóm khách nợ"
              label-for="customerCategory"
            >
              <v-select
                id="customerCategory"
                v-model="contract.debtor.category"
                class="w-100"
                :reduce="category => category.value"
                :options="$customerCategory.list"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- mã số thuế -->
          <b-col
            v-if="contract.debtor.category === 'company'"
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Mã số thuế"
              label-for="customerTaxCode"
            >
              <b-form-input
                id="customerTaxCode"
                v-model="contract.debtor.tax_code"
                placeholder="MST"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-else
            cols="12"
            sm="6"
          />

          <!--Số nhà, tên đường-->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Số nhà, tên đường"
              label-for="debtorStreet"
            >
              <b-form-input
                id="debtorStreet"
                v-model="contract.debtor.street"
                placeholder="Số nhà, tên đường"
              />
            </b-form-group>
          </b-col>

          <!--Phường/Xã -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Phường/Xã"
              label-for="debtorWard"
            >
              <b-form-input
                id="debtorWard"
                v-model="contract.debtor.ward"
                placeholder="Phường/Xã"
              />
            </b-form-group>
          </b-col>

          <!--Quận/Huyện -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Quận/Huyện"
              label-for="debtorDistrict"
            >
              <b-form-input
                id="debtorDistrict"
                v-model="contract.debtor.district"
                placeholder="Quận/Huyện"
              />
            </b-form-group>
          </b-col>

          <!--Tỉnh thành -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              label="Tỉnh thành"
              label-for="debtorProvince"
            >
              <b-form-input
                id="debtorProvince"
                v-model="contract.debtor.province"
                placeholder="Tỉnh thành"
              />
            </b-form-group>
          </b-col>

          <!--Danh xưng-->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              label="Danh xưng"
              label-for="debtorRepTitle"
            >
              <b-form-input
                id="customerGender"
                v-model="contract.debtor.rep_gender"
                placeholder="Danh xưng"
              />
            </b-form-group>
          </b-col>

          <!--Người đại diện pháp luật-->
          <b-col
            cols="12"
            sm="6"
            class="pl-sm-0"
          >
            <b-form-group
              label="Người đại diện pháp luật"
              label-for="debtorRepName"
            >
              <b-form-input
                id="debtorRepName"
                v-model="contract.debtor.rep_name"
                placeholder="Người đại diện pháp luật"
                class="text-primary"
              />
            </b-form-group>
          </b-col>

          <!--Chức vụ-->
          <b-col
            cols="12"
            sm="3"
            class="pl-sm-0"
          >
            <b-form-group
              label="Chức vụ"
              label-for="debtorRepJobTitle"
            >
              <b-form-input
                id="debtorRepJobTitle"
                v-model="contract.debtor.rep_job_title"
                placeholder="Chức vụ"
              />
            </b-form-group>
          </b-col>

          <!-- CCCD -->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              label="CCCD"
              label-for="debtor-cccd"
            >
              <b-form-input
                id="debtor-cccd"
                v-model="contract.debtor.rep_card_id"
                placeholder="CCCD"
              />
            </b-form-group>
          </b-col>

          <!--ngày cấp-->
          <b-col
            cols="12"
            sm="6"
            lg="4"
            class="pl-sm-0"
          >
            <b-form-group
              label="Ngày cấp"
              label-for="ngaycap-cmnd-debtor"
            >
              <flat-pickr
                id="ngaycap-cmnd-debtor"
                v-model="contract.debtor.rep_card_date"
                class="form-control"
                :config="{ altFormat: 'd/m/Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'd/m/Y', allowInput: false }"
              />
            </b-form-group>
          </b-col>

          <!--nơi cấp-->
          <b-col
            cols="12"
            sm="3"
            lg="5"
            class="pl-sm-0"
          >
            <b-form-group
              label="Nơi cấp"
              label-for="noicapaddressdebtor"
            >
              <b-form-input
                id="noicapaddressdebtor"
                v-model="contract.debtor.rep_card_place"
                placeholder="Nơi cấp"
              />
            </b-form-group>
          </b-col>

          <!--Người liên hệ-->
          <b-col
            cols="12"
            sm="5"
          >
            <b-form-group
              label="Người liên hệ"
              label-for="debtorContactName"
            >
              <b-form-input
                id="debtorContactName"
                v-model="contract.debtor.contact_name"
                placeholder="Người liên hệ"
              />
            </b-form-group>
          </b-col>

          <!--Email-->
          <b-col
            cols="12"
            sm="4"
            class="pl-sm-0"
          >
            <b-form-group
              label="Email"
              label-for="debtorContactEmail"
            >
              <b-form-input
                id="debtorContactEmail"
                v-model="contract.debtor.contact_email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>

          <!--Số điện thoại-->
          <b-col
            cols="12"
            sm="3"
            class="pl-sm-0"
          >
            <b-form-group
              label="Số điện thoại"
              label-for="debtorContactPhone"
            >
              <b-form-input
                id="debtorContactPhone"
                v-model="contract.debtor.contact_phone"
                placeholder="Số điện thoại"
              />
            </b-form-group>
          </b-col>

          <!-- Thông tin thêm -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Thông tin thêm "
              label-for="debtorMore"
            >
              <b-form-input
                id="debtorMore"
                v-model="contract.debtor.more"
                placeholder="Thông tin thêm "
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="contract.is_calculate_team_fund"
            >
              <span :class="contract.is_calculate_team_fund ? 'text-primary' : ''">
                Khách Nợ ở Nội Thành Hồ Chí Minh
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BRow,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BBadge,
    BCardBody,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    flatPickr,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    contract: {
      type: Object,
      default: null,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
