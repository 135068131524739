<template>
  <div>
    <b-tabs class="sticky-tabs">
      <b-tab
        active
        title="THÔNG TIN CHI TIẾT"
      >
        <top-info
          :contract="contract"
          @update-has-plan="updateHasPlan"
        />

        <div class="contract-info">
          <customer-form
            :contract="contract"
          />
          <debtor-form
            :contract="contract"
          />
        </div>

        <debt-content
          :contract="contract"
        />

        <debt-info-by-customer
          :contract="contract"
        />

        <debt-collection
          :contract="contract"
          :debt-collection-data="debtCollectionList"
          @add-debt="addDebtCollection"
          @edit-debt="editDebtCollection"
          @delete-debt="deleteDebtCollection"
        />
      </b-tab>
      <b-tab title="KẾT QUẢ XÁC MINH & TÀI LIỆU">
        <!--        -->
        <Verification
          :contract="contract"
        />
        <attachments
          ref="attachments"
          :document-files.sync="contract.document_files"
          :contract-status="contract.status"
          :is-uploading="isUploading"
          @all-updated="updateDocumentFiles"
        />
      </b-tab>
    </b-tabs>

    <comment-sidebar
      :comment="comment"
      @create-comment="createComment"
      @click="fetchComments"
    />

    <!--    fixed btn-->
    <fixed-right-button
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

    <b-modal
      id="modal-confirm-delete-contract"
      ref="modal-confirm-delete-contract"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      :title="'Xác nhận xóa' + (contract.contract_code ? ' Hợp đồng: ' : ' Hồ sơ: ') + (contract.contract_code || contract.document_code)"
      @ok="removeContract"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span>
        {{ (contract.contract_code ? 'Hợp đồng' : 'Hồ sơ') + ' mã số: ' }}
        <span class="text-primary font-weight-bolder">{{ (contract.contract_code || contract.document_code) }}</span> ?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCardText, BModal, BTab, BTabs, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import FixedRightButton from '@core/components/fixed-button/Fixed-Right-Button.vue'
import store from '@/store'
import contractStoreModule from '@/views/contracts/contractStoreModule'
import { onUnmounted } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import commentStoreModule from '@/views/comment/comentStoreModule'
import useContractsCreate from '@/views/contracts/create/useContractsCreate'
import Verification from '@/views/contracts/detail/Verification.vue'
import DebtCollection from '@/views/contracts/detail/DebtCollection.vue'
import http from '@/global/http'
import CustomerForm from '../create/CustomerForm.vue'
import DebtorForm from '../create/DebtorForm.vue'
import DebtContent from '../create/DebtContent.vue'
import DebtInfoByCustomer from '../create/DebtInfoByCustomer.vue'
import TopInfo from '../detail/TopInfo.vue'
import Attachments from '../create/Attachments.vue'
import CommentSidebar from '../detail/Comment-Sidebar.vue'

export default {
  components: {
    CustomerForm,
    DebtorForm,
    DebtContent,
    DebtInfoByCustomer,
    TopInfo,
    BTabs,
    BTab,
    BCardText,
    BModal,
    Attachments,
    CommentSidebar,
    FixedRightButton,
    Verification,
    DebtCollection,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      comment: [],
      isUploading: false,
    }
  },
  computed: {
    actionsButton() {
      return [{
        label: 'Cập nhật',
        icon: 'SaveIcon',
        value: 'save',
        suggest: true,
      },
      {
        label: 'Xóa',
        icon: 'DeleteIcon',
        value: 'delete',
      }]
    },
  },
  created() {
    this.$action(this.fetchContract(this.$route.params.id))
      .then(() => {
        this.fetchComments()
      })
    this.fetchDebtCollection(this.$route.params.id)
  },
  methods: {
    updateHasPlan(isHasPlan) {
      this.$action(http.api(store.dispatch('contract/updateHasPlan', {
        id: this.contract.id,
        is_has_plan: isHasPlan,
      }), () => {
        this.contract.is_has_plan = isHasPlan
      }), true)
    },
    handleActionsButton(btn) {
      if (btn.value === 'save') {
        const pending = this.$refs.attachments.$refs.uploads.$refs.uploads.getQueuedFiles()
        if (pending && pending.length) {
          this.isUploading = true
          this.$refs.attachments.$refs.uploads.$refs.uploads.processQueue()
        } else {
          this.saveContract()
        }
        return
      }
      if (btn.value === 'delete') {
        this.openModalConfirmDeleteContract()
      }
    },
    updateDocumentFiles(files) {
      if (!this.isUploading) return
      this.contract.document_files = files
      this.saveContract()
    },
    saveContract() {
      this.$action(this.updateContract(this.contract), true)
        .then(data => {
          this.$router.push({
            name: 'contracts-detail',
            params: { id: data.id },
          })
        })
        .finally(() => {
          this.isUploading = false
        })
    },
    openModalConfirmDeleteContract() {
      this.$refs['modal-confirm-delete-contract'].show()
    },
    removeContract() {
      this.$action(this.deleteContract(this.contract.id), true)
        .then(() => {
          this.$router.push({ name: 'contracts-list' })
        })
    },
    fetchComments() {
      this.$action(store.dispatch('comment/fetchCommentByContract', { id: this.contract.id }))
        .then(res => {
          this.comment = res.data.reverse()
        })
    },
    createComment({
      content,
      type,
    }) {
      const data = {
        content,
        type,
        full_name: getUserData().fullname,
        user_id: getUserData().id,
        contract_id: this.contract.id,
      }
      this.$action(store.dispatch('comment/createComment', data), true)
        .then(res => {
          this.comment.unshift(res.data)
        })
    },
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = 'contract'
    const COMMENT_STORE_MODULE_NAME = 'comment'
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule)
    if (!store.hasModule(COMMENT_STORE_MODULE_NAME)) store.registerModule(COMMENT_STORE_MODULE_NAME, commentStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME)
      if (store.hasModule(COMMENT_STORE_MODULE_NAME)) store.unregisterModule(COMMENT_STORE_MODULE_NAME)
    })

    return {
      ...useContractsCreate(),
    }
  },
}
</script>
<style lang="scss">
.contract-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: .5rem;
}

@media (max-width: 992px) {
  .contract-info {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .sticky-tabs > *:nth-child(1) {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 20%);
    position: sticky;
    top: 63px;
    z-index: 99;
    background: white;
    border-radius: .5rem;
  }
}
</style>
